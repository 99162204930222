import { AlertSecurity, Card, Content } from "../../components";
import { UseServiceContext } from "../../contexts/service";
import { elipseText, formatterCurrency } from "../../utils";

const ServicePage = () => {
    const { navigation, category, list, onChangeTabCategory } = UseServiceContext();

    return (
        <Content>
            <div className="mt-5">
                <AlertSecurity />
            </div>
            <div className="mt-5">
                <h1 className="font-bold text-xl">Layanan</h1>
                <span>Aman dan terpercaya, telah diverifikasi oleh pusproject</span>
            </div>
            <div className="flex gap-2 py-2 mt-2 sticky top-0 bg-white">
                {category.map((item, index) => {
                    return (
                        <div key={index} className={`rounded-lg border py-2 px-3 cursor-pointer ${item.active ? 'bg-violet-500 text-white' : 'hover:bg-slate-50'}`} onClick={() => onChangeTabCategory(index)}>
                            <span className="font-medium">{item.title}</span>
                        </div>
                    );
                })}
            </div>
            <div className="mt-6 flex gap-3">
                <div className="w-60">
                    <Card className="sticky top-16">
                        <span className="font-bold text-sm">Filter</span>
                        <div className="mt-2">
                            <span className="font-bold text-sm">Harga</span>
                            <div className="flex gap-2">
                                <span className="font-medium text-md">{`< 2.000.000`}</span>
                            </div>
                            <div className="flex gap-2">
                                <span className="font-medium text-md">{`> 2.000.000`}</span>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="grow">
                    <div className="grid grid-cols-4 gap-2">
                        {list.map((item, index) => {
                            return (
                                <Card key={index} className="shadow-sm p-2 cursor-pointer" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                    <div className="bg-slate-400 h-[13rem] w-[100%] rounded-lg border border-violet-100">
                                        <img src={item.service_file_model[0].path} alt={item.title} className="w-full h-full rounded-lg" />
                                    </div>
                                    <div className="py-1">
                                        <div className="leading-4">
                                            <span className="text-md font-medium">{elipseText(item.title, 50)}</span>
                                        </div>
                                        <div className="mt-1">
                                            <span className="text-[1rem] font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                        </div>
                                        <div className="mt-1 text-[11px] flex flex-wrap gap-2">
                                            {item.tag_encode.map((itemChild, indexChild) => {
                                                return (
                                                    <div key={indexChild} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{itemChild}</div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default ServicePage;