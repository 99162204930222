const REACT_APP_API_URL = `${process.env.REACT_APP_ENV}/api/v1/`;

// Auth
export const API_LOGIN = `${REACT_APP_API_URL}login`;
export const API_REGISTER = `${REACT_APP_API_URL}register`;
export const API_REGISTER_GOOGLE = `${REACT_APP_API_URL}register/google`;
// User
export const API_USER_UPDATE = `${REACT_APP_API_URL}user/update`;
// Service
export const API_SERVICE_SHOW = `${REACT_APP_API_URL}service`;
export const API_SERVICE_DETAIL = `${REACT_APP_API_URL}service/detail`;
// Service Category
export const API_SERVICE_CATEGORY = `${REACT_APP_API_URL}service/category`;
// Service Include
export const API_SERVICE_INCLUDE = `${REACT_APP_API_URL}service/include`;
// Service Comment
export const API_SERVICE_COMMENT = `${REACT_APP_API_URL}service/comment`;
// Checkout
export const API_CHECKOUT_SHOW = `${REACT_APP_API_URL}checkout`;
export const API_CHECKOUT_STORE = `${REACT_APP_API_URL}checkout/store`;
// Payment
export const API_PAYMENT_SHOW = `${REACT_APP_API_URL}payment`;
export const API_PAYMENT_DETAIL = `${REACT_APP_API_URL}payment/detail`;
export const API_PAYMENT_STORE = `${REACT_APP_API_URL}payment/store`;
// Payment Methods
export const API_PAYMENT_METHODS = `${REACT_APP_API_URL}payment/methods`;