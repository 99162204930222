import { useNavigate } from "react-router-dom";
import { AvAvatar1 } from "../../../assets";
import { getLocalUser } from "../../../utils";
import Card from "../card/Card";

const SettingAside = () => {
    const navigation = useNavigate();
    const user = getLocalUser();

    return (
        <div className="w-72">
            <Card className="py-[0.5rem]">
                <div className="py-1 cursor-pointer" onClick={() => navigation('/profile')}>
                    <div className="flex items-center justify-between pr-3">
                        <div className="flex gap-3 items-center">
                            {user.authFromServer.data.user.photo ? (<img src={user.authFromServer.data.user.photo} alt="FotoProfile" className="w-8 h-8 rounded-full" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-8 h-8 rounded-full" />)}
                            <div>
                                <span className="font-bold">{user.authFromServer.data.user.name ? user.authFromServer.data.user.name.split(' ')[0] : 'Anonim'}</span>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </div>
                </div>
            </Card>
            <Card className="mt-2">
                <div>
                    <h5 className="font-bold text-md">
                        Pengaturan
                    </h5>
                    <ul className="mt-2">
                        <li className="px-3 py-1 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/setting/profile')}>
                            <div className="flex items-center justify-between">
                                Biodata Diri
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-1 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/setting/account')}>
                            <div className="flex items-center justify-between">
                                Akun
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    );
}

export default SettingAside;