import { useNavigate } from "react-router-dom";
import { AvAvatar1 } from "../../../assets";
import { UseAuthContext } from "../../../contexts/auth";
import { getLocalUser } from "../../../utils";
import { Button } from "../../atoms";
import Card from "../card/Card";

const MenuProfileModal = (props) => {
    const user = getLocalUser();
    const navigation = useNavigate();
    const { googleSignOut } = UseAuthContext();

    const navigateToProvider = () => {
        window.open(`${process.env.REACT_APP_PROVIDER}?token=${user.authFromServer.data.token}`);
    }

    return (
        <div className="absolute top-[3rem] right-5 w-80 pt-1 cursor-pointer" onMouseOver={() => props.onMouseOver && props.onMouseOver()} onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}>
            <Card className="shadow-lg">
                <div className="flex items-center gap-3">
                    {props.user.authFromServer.data.user.photo ? (<img src={props.user.authFromServer.data.user.photo} alt="FotoProfile" className="w-[40px] h-[40px] rounded-full shadow-md" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-[40px] h-[40px] rounded-full shadow-md" />)}
                    <div className="leading-3 -mt-2">
                        <h4 className="font-bold text-lg">{props.user.authFromServer.data.user.name ? props.user.authFromServer.data.user.name.split(' ')[0] : 'Anonim'}</h4>
                        <span className="font-medium">{props.user.authFromServer.data.user.email}</span>
                    </div>
                </div>
                <div className="border-t w-full h-[1px] my-3" />
                {props.user.authFromServer.data.provider && (
                    <>
                        <div className="flex items-center gap-3">
                            {props.user.authFromServer.data.provider.photo_full ? (<img src={props.user.authFromServer.data.provider.photo_full} alt="FotoProfile" className="w-[40px] h-[40px] rounded-full shadow-md" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-[40px] h-[40px] rounded-full shadow-md" />)}
                            <div className="leading-4 -mt-2">
                                <h4 className="font-bold text-[0.9rem]">{props.user.authFromServer.data.provider.name ? props.user.authFromServer.data.provider.name.split(' ')[0] : 'Anonim'}</h4>
                                <span className="font-medium">{props.user.authFromServer.data.provider.domain}</span>
                            </div>
                        </div>
                        <div className="mt-3">
                            <Button className="border border-violet-500" onClick={() => navigateToProvider()}>
                                Kelola Provider
                            </Button>
                        </div>
                        <div className="border-t w-full h-[1px] my-3" />
                    </>
                )}
                {!props.user.authFromServer.data.provider && (
                    <>
                        <div className="flex flex-col gap-3">
                            <div className="leading-3">
                                <div className="flex gap-2 items-center">
                                    <span className="font-bold">Daftar Provider</span>
                                    <div className="rounded-full px-2 py-1 bg-yellow-100 text-yellow-700 font-bold text-xs">Segera Hadir</div>
                                </div>
                                <div className="mt-1">
                                    <small>Jadilah penyedia yang terpercaya</small>
                                </div>
                            </div>
                            {/* <div>
                                <Button className="bg-violet-500 text-white" onClick={() => navigateToProvider()}>
                                    Daftar
                                </Button>
                            </div> */}
                        </div>
                        <div className="border-t w-full h-[1px] my-3" />
                    </>
                )}
                <div className="mt-2">
                    <ul>
                        <li className="py-1 px-2 hover:bg-slate-100 rounded-lg" onClick={() => navigation('/profile')}>Biodata</li>
                        <li className="py-1 px-2 hover:bg-slate-100 rounded-lg">
                            <div className="flex gap-2">
                                <span>Pencapaian</span>
                                <div className="rounded-full px-2 py-1 bg-yellow-100 text-yellow-700 font-bold text-xs">Segera Hadir</div>
                            </div>
                        </li>
                        <li className="py-1 px-2 hover:bg-slate-100 rounded-lg">
                            <div className="flex gap-2">
                                <span>Pengaturan</span>
                                <div className="rounded-full px-2 py-1 bg-yellow-100 text-yellow-700 font-bold text-xs">Segera Hadir</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="border-t w-full h-[1px] my-3" />
                <div className="mt-2">
                    <Button className="bg-slate-200 hover:bg-slate-300" onClick={() => googleSignOut()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2"></path>
                            <path d="M15 12h-12l3 -3"></path>
                            <path d="M6 15l-3 -3"></path>
                        </svg>
                        <span className="font-medium">Keluar</span>
                    </Button>
                </div>
            </Card>
        </div>
    );
}
export default MenuProfileModal;