import axios from "axios";
import { API_USER_UPDATE } from "../../configs/api";

export const updateUserRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_USER_UPDATE, body, {
            headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}
