import { Button, Card, Content, ProfileAside } from "../../../components";
import { UsePaymentListContext } from "../../../contexts/payment/list";
import { dateFormatter, validateHandleText } from "../../../utils";

const PaymentListPage = () => {
    const { location, payment, navigation } = UsePaymentListContext();

    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <ProfileAside />
                <Card className="grow">
                    <h3 className="font-bold text-md">{location.state.title}</h3>
                    {payment.map((item, index) => {
                        return (
                            <Card key={index} className="mt-2">
                                <div className="flex gap-3 mt-4">
                                    <div className="bg-slate-400 rounded-xl h-20 w-20 border overflow-hidden">
                                        <img src={item.checkout_model.service_encode.service_file_model[0].path} alt="ImageServices" className="w-full h-full" />
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-base">{item.checkout_model.service_encode.title}</h4>
                                        <div className="mt-2 text-[11px] flex gap-3">
                                            <div className="rounded-full px-3 py-1 bg-green-100 text-green-700 font-bold">{item.status}</div>
                                            <div className="flex gap-1 items-center cursor-pointer bg-slate-100 hover:bg-slate-200 px-2 rounded-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M8 9h8"></path>
                                                    <path d="M8 13h6"></path>
                                                    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                                                </svg>
                                                <span className="font-bold">Chat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-4 mb-2" />
                                <div className="flex flex-wrap justify-between items-center">
                                    <div className="leading-4">
                                        <span className="text-sm text-slate-400">Batas akhir Pembayaran</span>
                                        <h3 className="text-md font-semibold">{validateHandleText(item.expiration_date, dateFormatter(item.expiration_date))}</h3>
                                    </div>
                                    <div>
                                        <Button className="bg-violet-500 text-white" onClick={() => navigation('/payment', { state: { checkout_id: item.checkout_id } })}>Bayar Sekarang</Button>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </Card>
            </div>
        </Content>
    );
}

export default PaymentListPage;