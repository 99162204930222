import { AssetBanner1, AssetBanner2, AssetBanner3, AssetBanner4 } from "../../assets";
import { Button, Card, Content, InputSearch, SimpleCorousel } from "../../components";
import { UseMainContext } from "../../contexts/main";
import { elipseText, formatterCurrency } from "../../utils";

const MainApp = () => {
    const { navigation, service, category, categoryActive, categorys, popular, list, onChangeTabService, onChangeTabCategory, onChangeTabCategorys } = UseMainContext();

    return (
        <Content jumbotron={AssetBanner1}>
            <div className="flex justify-center pt-20">
                <div className="w-[41.563rem]">
                    <InputSearch placeholder="Cari..." />
                </div>
            </div>
            <div className="text-center mt-2">
                <span className="text-white font-semibold">Bangun Project Impianmu Jadi Kenyataan...</span>
            </div>
            {/* Kategori */}
            <div className="mt-10">
                <div className="flex justify-between bg-white rounded-xl shadow-md">
                    <div className="grow px-6 py-4 leading-3">
                        <span className="font-bold text-lg">Layanan</span>
                        <br />
                        <small className="font-medium">Pemesanan layanan online, Sewa dan pembelian layanan online</small>
                    </div>
                    <div className="grow px-6 py-4 leading-3 border-x border-slate-300">
                        <div className="flex gap-2 flex-wrap">
                            <span className="font-bold text-lg">Produk</span>
                            <div>
                                <div className="rounded-full px-2 py-1 bg-yellow-100 text-yellow-700 font-bold text-xs">Segera Hadir</div>
                            </div>
                        </div>
                        <small className="font-medium">Produk siap Anda gunakan, Aplikasi {'&'} Design</small>
                    </div>
                    <div className="grow px-6 py-4 leading-3">
                        <div className="flex gap-2 flex-wrap">
                            <span className="font-bold text-lg">Bisnis</span>
                            <div>
                                <div className="rounded-full px-2 py-1 bg-yellow-100 text-yellow-700 font-bold text-xs">Segera Hadir</div>
                            </div>
                        </div>
                        <small className="font-medium">Kerja sama bisnis</small>
                    </div>
                </div>
            </div>
            {/* Banner */}
            <div className="my-7">
                <div className="flex gap-3">
                    <div className="bg-violet-400 rounded-xl grow overflow-hidden">
                        <img src={AssetBanner2} alt="Banner-Pusproject" />
                    </div>
                    <div className="bg-violet-400 rounded-xl min-w-[35%] w-[35%] max-w-[35%] overflow-hidden">
                        <img src={AssetBanner3} alt="Banner-Pusproject" />
                    </div>
                </div>
            </div>
            {/* Populer */}
            <div className="bg-white rounded-xl p-6 border shadow-sm">
                <span className="font-bold text-lg">Layanan Terbaru</span>
                <div className="mt-3">
                    <SimpleCorousel layoutType={0}>
                        {popular.map((item, index) => {
                            return (
                                <Card key={index} className="flex gap-3 cursor-pointer min-w-[29rem] w-[29rem] max-w-[29rem]" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                    <div className="min-w-[8rem] w-[8rem] max-w-[8rem] rounded-xl bg-slate-400 overflow-hidden">
                                        <img src={item.service_file_model[0].path} alt={item.title} />
                                    </div>
                                    <div className="leading-4 py-2">
                                        <div className="leading-4">
                                            <span className="text-md font-medium">{elipseText(item.title, 50)}</span>
                                        </div>
                                        <div className="mt-2">
                                            <span className="text-[1rem] font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                        </div>
                                        <div className="mt-3 text-[11px] flex gap-2">
                                            {item.tag_encode.map((cItem, cIndex) => {
                                                return (
                                                    <div key={cIndex} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{cItem}</div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                    </SimpleCorousel>
                </div>
            </div>
            {/* Kategori Jumbo Banner */}
            <div className="py-4 flex flex-wrap justify-between">
                <div className="mt-20 flex-1">
                    <span className="font-bold text-xl">Kategori</span>
                    <br />
                    <span>Kategori Layanan</span>
                    <div className="flex gap-2 mt-4">
                        {service.map((item, index) => {
                            return (
                                <div key={index} className={`rounded-lg border py-2 px-3 cursor-pointer ${item.active ? 'bg-violet-500 text-white' : 'hover:bg-slate-50'}`} onClick={() => onChangeTabService(index)}>
                                    <span className="font-medium">{item.category}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex flex-col gap-3 mt-5">
                        {category.map((item, index) => {
                            return (
                                <div key={index} className={`rounded-lg border grow py-4 px-3 cursor-pointer hover:bg-slate-50 ${item.active ? 'border-violet-500' : ''}`} onClick={() => onChangeTabCategory(index)}>
                                    <span className="font-medium">{item.title}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="grow">
                    <div className="flex justify-center">
                        <img src={categoryActive.path} alt="Background-bg" className="max-h-[25rem]" />
                    </div>
                    <div className="text-center">
                        <span className="font-bold text-2xl">{categoryActive.title}</span>
                        <div className="flex justify-center">
                            <span className="mt-4 max-w-[32rem]">
                                {categoryActive.description}
                            </span>
                        </div>
                        <div className="flex justify-center mt-7 gap-2">
                            <Button className="border-2 border-violet-500 text-violet-500" onClick={() => navigation('/service')}>
                                Tampilkan Lebih Banyak...
                            </Button>
                            <Button className="bg-violet-500 text-white">
                                Pesan Sekarang
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section Banner */}
            <div className="bg-slate-400 mt-16 mb-7 rounded-xl overflow-hidden">
                <img src={AssetBanner4} alt="Banner-Pusproject" />
            </div>
            {/* Section layanan */}
            <div>
                <h2 className="font-bold text-xl">Layanan</h2>
                <span>Aman dan terpercaya, layanan yang tersedia di pusproject</span>
                <div className="py-5">
                    <div className="flex flex-wrap gap-2 justify-between">
                        <div className="flex flex-wrap gap-2 grow">
                            {categorys.map((item, index) => {
                                return (
                                    <div key={index} className={`rounded-lg border py-2 px-3 cursor-pointer ${item.active ? 'bg-violet-500 text-white' : 'hover:bg-slate-50'}`} onClick={() => onChangeTabCategorys(index)}>
                                        <span className="font-medium">{item.title}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <Button className="border py-2 px-3" onClick={() => navigation('/service')}>
                            Lainnya...
                        </Button>
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-2">
                    {list.map((item, index) => {
                        return (
                            <Card key={index} className="shadow-sm p-2 cursor-pointer" onClick={() => navigation(`/service/detail/${item.id}/${encodeURIComponent(item.title)}`)}>
                                <div className="bg-slate-400 h-[13rem] w-[100%] rounded-lg">
                                    <img src={item.service_file_model[0].path} alt={item.title} className="w-full h-full rounded-lg" />
                                </div>
                                <div className="py-1">
                                    <div className="leading-4">
                                        <span className="text-md font-medium">{elipseText(item.title, 50)}</span>
                                    </div>
                                    <div className="mt-1">
                                        <span className="text-[1rem] font-bold">{formatterCurrency(item.service_tier_model[0].price)}</span>
                                    </div>
                                    <div className="mt-1 text-[11px] flex flex-wrap gap-2">
                                        {item.tag_encode.map((itemChild, indexChild) => {
                                            return (
                                                <div key={indexChild} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{itemChild}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </Content>
    );
}

export default MainApp;