import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { showServiceCategoryRequest, showServiceRequest } from "../../request";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [service, setService] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryActive, setCategoryActive] = useState({});
    const [popular, setPopular] = useState([]);
    const [list, setList] = useState([]);
    const [categorys, setCategorys] = useState([
        {
            title: 'Semua',
            title_key: '',
            active: true,
        }
    ]);

    const getPopular = async () => {
        await showServiceRequest({ perpage: 5 }).then((res) => {
            setPopular(res.data.service.data);
        });
    }

    const getCategory = async () => {
        await showServiceCategoryRequest({}).then((res) => {
            setUpCategory(res.data);
        });
    }

    const getService = async (categoryTitle = '') => {
        await showServiceRequest({ category: categoryTitle }).then((res) => {
            setList(res.data.service.data);
        });
    }

    const setUpCategory = (data) => {
        let serviceData = data.category.data;
        serviceData[0].active = true;
        let categoryData = data.category.data[0].child_encode;
        categoryData[0].active = true;
        let categorysData = [...categorys, ...data.categoryFirst.child_encode];

        setService(serviceData);
        setCategoryActive(categoryData[0]);
        setCategory(categoryData);
        setCategorys(categorysData);
    }

    const onChangeTabService = (index) => {
        service.forEach((item) => {
            item.active = false;
        });

        category.forEach((item) => {
            item.active = false;
        });

        service[index].active = true;
        service[index].child_encode[0].active = true;

        setService([...service]);
        setCategory(service[index].child_encode);
    }

    const onChangeTabCategory = (index) => {
        category.forEach((item) => {
            item.active = false;
        });

        category[index].active = true;
        setCategoryActive(category[index]);
        setCategory([...category]);
    }

    const onChangeTabCategorys = (index) => {
        categorys.forEach((item) => {
            item.active = false;
        });

        categorys[index].active = true;
        getService(categorys[index].title_key);
        setCategorys([...categorys]);
    }

    useEffect(() => {
        getPopular();
        getCategory();
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainContext.Provider value={{ navigation, service, category, categoryActive, categorys, popular, list, onChangeTabService, onChangeTabCategory, onChangeTabCategorys }}>
            {children}
        </MainContext.Provider>
    );
}

export const UseMainContext = () => {
    return useContext(MainContext);
}