const AssetShapesBg = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 349 319" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M184.239 31.5158C295.914 50.2877 350.734 196.643 345.254 263.244C339.773 329.845 277.896 287.299 194.546 301.317C111.196 315.334 -34.9079 280.419 28.5517 191.075C92.0113 101.73 72.5633 12.744 184.239 31.5158Z" fill="#F3F2FF" />
            <ellipse cx="259.244" cy="83.92" rx="30.8766" ry="29.0918" fill="white" />
            <ellipse cx="224.034" cy="47.6829" rx="36.8353" ry="34.706" fill="white" />
            <ellipse cx="28.808" cy="28.2586" rx="28.808" ry="28.2586" transform="matrix(0.829459 -0.558567 0.604364 0.796708 190.448 32.1824)" fill="#F2F2FF" />
            <ellipse cx="17.5334" cy="17.1989" rx="17.5334" ry="17.1989" transform="matrix(0.829459 -0.558567 0.604364 0.796708 240.284 74.4154)" fill="#F2F2FF" />
        </svg>
    );
}

export default AssetShapesBg;