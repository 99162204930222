import { AvAvatar1, IcChecklist, IlDoubleChat } from "../../../assets";
import { Button, Card, Content, Table, TableBody, TableHead, TableTd, TableTh, TableTr } from "../../../components";
import { UseServiceDetailContext } from "../../../contexts/service/detail";
import { elipseText, formatterCurrency } from "../../../utils";

const ServiceDetailPage = () => {
    const { data, serviceTier, serviceTierInclude, serviceComment, onChangeTabServiceTier, onCheckout } = UseServiceDetailContext();

    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <div className="grow">
                    <div className="flex gap-10">
                        <div>
                            <div className="sticky top-5">
                                <div className="h-80 w-80 bg-slate-400 rounded-xl border border-violet-100">
                                    <img src={data.service_file_model?.[0]?.path} alt={data.title} className="w-full h-full rounded-xl" />
                                </div>
                                <div className="flex gap-2 my-3">
                                    {data.service_file_model?.map((item, index) => {
                                        return (
                                            <div key={index} className="h-14 w-14 bg-slate-400 rounded-md border border-violet-100">
                                                <img src={item.path} alt="ImageServices" className="w-full h-full rounded-md" />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="grow">
                            <h1 className="font-bold text-xl">{data.title}</h1>
                            <span>Aktif: Sedang Aktif</span>
                            <div className="mt-2 text-[11px] flex flex-wrap gap-2">
                                {data.tag_encode?.map((item, index) => {
                                    return (
                                        <div key={index} className="rounded-full px-2 py-1 bg-green-100 text-green-700 font-bold">{item}</div>
                                    );
                                })}
                            </div>
                            <div className="mt-5">
                                <h2 className="font-bold text-lg">Deskripsi Layanan</h2>
                                <p>
                                    {data.detail}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="py-2">
                            <h2 className="font-bold text-lg">
                                Apa Yang Termasuk
                            </h2>
                        </div>
                        <div className="border rounded-lg py-3">
                            <Table>
                                <TableHead>
                                    <TableTr>
                                        <TableTh>Layanan</TableTh>
                                        {data.service_tier_model?.map((item, index) => {
                                            return (
                                                <TableTh key={index} className="text-center">
                                                    <div>{item.title}</div>
                                                    <div>{formatterCurrency(item.price)}</div>
                                                </TableTh>
                                            );
                                        })}
                                    </TableTr>
                                </TableHead>
                                <TableBody>
                                    {data.service_include_model?.map((item, index) => {
                                        return (
                                            <TableTr key={index}>
                                                <TableTd className={`${index % 2 === 0 ? 'bg-slate-100' : ''}`}>
                                                    <span>{item.title}</span>
                                                </TableTd>
                                                {item.tier_encode.map((itemChild, indexChild) => {
                                                    return (
                                                        <TableTd key={indexChild} className={`text-center ${index % 2 === 0 ? 'bg-slate-100' : ''}`}>{itemChild.value ? (<div className="flex justify-center"><IcChecklist width="21" height="21" className="text-green-700" /></div>) : '-'}</TableTd>
                                                    );
                                                })}
                                            </TableTr>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <div className="flex gap-3 mt-8">
                        <div className="w-64">
                            <h2 className="font-bold text-lg">Ulasan {`&`} Penilaian</h2>
                            <div className="rounded-lg py-3">
                                <div className="flex gap-1 items-end justify-center">
                                    <h2 className="font-bold text-3xl">4.5</h2>
                                    <div className="text-gray-400 bottom-0 font-semibold">/ 5.0</div>
                                </div>
                            </div>
                        </div>
                        <div className="grow">
                            <h2 className="font-bold text-lg">Ulasan</h2>
                            <div className="mt-4">
                                {serviceComment.length === 0 && (
                                    <div className="text-center">
                                        <div className="flex justify-center">
                                            <img src={IlDoubleChat} alt="IllustrationDoubleChat" className="w-56" />
                                        </div>
                                        <div className="bg-white leading-3">
                                            <h4 className="font-semibold text-lg">Tidak ada ulasan</h4>
                                            <small>Belum ada ulasan untuk layanan ini</small>
                                        </div>
                                    </div>
                                )}
                                {serviceComment.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="flex gap-3 items-center">
                                                {item.user_model.photo ? (<img src={item.user_model.photo} alt="FotoProfile" className="w-[30px] h-[30px] rounded-full" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-[30px] h-[30px] rounded-full" />)}
                                                <div className="font-semibold">{item.user_model.name}</div>
                                            </div>
                                            <div className="mt-3">
                                                {item.comment}
                                            </div>
                                            {(index + 1) !== serviceComment.length && <hr className="my-4" />}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Card className="w-80 bg-slate-400 border border-slate-300 sticky top-5">
                        <div className="flex justify-around">
                            {data.service_tier_model?.map((item, index) => {
                                return (
                                    <div key={index} className={`py-2 grow cursor-pointer ${index > 0 && 'border-l'} ${!item.active && 'bg-slate-100'}`} onClick={() => onChangeTabServiceTier(index)}>
                                        <div className={`text-center ${item.active && 'text-violet-500 font-bold'}`}>
                                            <span>{item.title}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="border-b" />
                        <div className="pt-4">
                            <div className="flex justify-between items-center">
                                <span>Tingkat</span>
                                <span className="font-medium">{serviceTier.title}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span>Harga</span>
                                <span className="font-bold text-[18px]">{formatterCurrency(serviceTier.price ?? 0)}</span>
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="my-5">
                            <ul>
                                {serviceTierInclude.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="flex justify-between items-center">
                                                <span>{elipseText(item.title, 25)}</span>
                                                <IcChecklist width="16" height="16" className="text-green-700" />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="flex flex-col gap-2 justify-center pt-4">
                            <Button className="bg-violet-500 text-white py-[0.5rem]" onClick={() => onCheckout()}>Lanjutkan</Button>
                            <Button className="border-2 border-violet-500 flex gap-1 py-[0.4rem]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M8 9h8"></path>
                                    <path d="M8 13h6"></path>
                                    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                                </svg>
                                Chat
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </Content>
    );
}

export default ServiceDetailPage;