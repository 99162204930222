import axios from "axios";
import { API_SERVICE_COMMENT } from "../../configs/api";

export const showServiceCommentRequest = async ({ id = '' }) => {
    try {
        const response = await axios.get(`${API_SERVICE_COMMENT}?id=${id}`);
        return response.data;
    } catch (error) {
        return false;
    }
}