const AssetDocumentToPusproject = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 485 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 59.5C25.5 52.737 28.1866 46.251 32.9688 41.4688C37.751 36.6866 44.237 34 51 34H153C159.763 34 166.249 36.6866 171.031 41.4688C175.813 46.251 178.5 52.737 178.5 59.5V144.5C178.5 151.263 175.813 157.749 171.031 162.531C166.249 167.313 159.763 170 153 170H51C44.237 170 37.751 167.313 32.9688 162.531C28.1866 157.749 25.5 151.263 25.5 144.5V59.5Z" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M59.5 85C59.5 89.5087 61.2911 93.8327 64.4792 97.0208C67.6673 100.209 71.9913 102 76.5 102C81.0087 102 85.3327 100.209 88.5208 97.0208C91.7089 93.8327 93.5 89.5087 93.5 85C93.5 80.4913 91.7089 76.1673 88.5208 72.9792C85.3327 69.7911 81.0087 68 76.5 68C71.9913 68 67.6673 69.7911 64.4792 72.9792C61.2911 76.1673 59.5 80.4913 59.5 85Z" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M127.5 68H144.5" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M127.5 102H144.5" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M59.5 136H144.5" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <g filter="url(#filter0_d_749_50)">
                <path d="M434.417 81.1266C435.04 114.439 408.555 141.949 375.261 142.572C341.968 143.195 314.473 116.695 313.85 83.3827C313.228 50.0703 339.713 22.5603 373.006 21.9373C406.3 21.3143 433.795 47.8143 434.417 81.1266Z" fill="#D0C9FD" />
                <path d="M367.796 128.615C361.032 131.314 357.627 139.103 361.71 145.136C368.209 154.74 377.361 162.377 388.177 167.029C403.066 173.434 419.889 173.658 434.943 167.652C449.998 161.647 462.052 149.903 468.453 135.006C473.103 124.184 474.493 112.341 472.605 100.897C471.419 93.7088 463.592 90.4001 456.829 93.0983C450.065 95.7965 447.053 103.576 447.052 110.862C447.051 115.536 446.108 120.208 444.228 124.585C440.589 133.054 433.736 139.73 425.178 143.145C416.619 146.559 407.056 146.431 398.591 142.79C394.216 140.908 390.32 138.168 387.105 134.776C382.095 129.49 374.559 125.917 367.796 128.615Z" fill="#9277FF" />
                <path d="M355.414 80.9346C354.698 91.2655 362.489 100.221 372.814 100.936C383.139 101.652 392.089 93.8573 392.804 83.5264C393.519 73.1956 385.729 64.2405 375.404 63.5248C365.079 62.8091 356.129 70.6038 355.414 80.9346ZM384.284 82.9359C383.895 88.5587 379.024 92.8011 373.404 92.4116C367.784 92.022 363.544 87.148 363.934 81.5252C364.323 75.9024 369.194 71.66 374.814 72.0495C380.433 72.4391 384.673 77.313 384.284 82.9359Z" fill="#9277FF" />
                <path d="M443.564 61.5229C442.849 71.8538 450.639 80.8088 460.964 81.5245C471.289 82.2402 480.239 74.4456 480.954 64.1147C481.67 53.7838 473.879 44.8288 463.554 44.1131C453.229 43.3974 444.279 51.192 443.564 61.5229ZM472.899 63.5563C472.491 69.4355 467.398 73.8714 461.522 73.4641C455.646 73.0568 451.213 67.9605 451.62 62.0813C452.027 56.2021 457.12 51.7662 462.996 52.1735C468.872 52.5808 473.306 57.677 472.899 63.5563Z" fill="#9277FF" />
                <path d="M420.749 92.168C427.836 93.8431 432.346 101.047 429.194 107.615C424.778 116.814 418.097 124.828 409.691 130.852C397.684 139.456 382.969 143.419 368.267 142.006C353.566 140.594 339.873 133.902 329.722 123.168C319.571 112.434 313.648 98.385 313.05 83.6196C312.452 68.8543 317.219 54.3717 326.468 42.8511C335.718 31.3305 348.824 23.5514 363.363 20.9529C377.902 18.3543 392.89 21.1122 405.554 28.7162C414.419 34.0397 421.726 41.4861 426.872 50.2979C430.545 56.5884 426.632 64.1342 419.704 66.3776C412.777 68.6211 405.495 64.5568 400.845 58.9495C398.362 55.9543 395.374 53.3738 391.985 51.3389C384.786 47.0161 376.265 45.4483 368 46.9255C359.735 48.4028 352.284 52.8251 347.025 59.3745C341.767 65.9239 339.057 74.1571 339.397 82.551C339.738 90.945 343.104 98.9317 348.875 105.034C354.646 111.136 362.43 114.94 370.788 115.743C379.146 116.546 387.511 114.294 394.337 109.402C397.55 107.1 400.32 104.286 402.553 101.099C406.733 95.1337 413.663 90.4929 420.749 92.168Z" fill="#9277FF" />
            </g>
            <path d="M254 91.2501V81.9862C254 81.4754 254.152 80.976 254.436 80.5513C254.719 80.1266 255.123 79.7955 255.595 79.6001C256.067 79.4046 256.586 79.3534 257.087 79.4531C257.588 79.5527 258.048 79.7986 258.41 80.1598L275.424 97.1736C275.908 97.6581 276.18 98.3151 276.18 99.0001C276.18 99.6851 275.908 100.342 275.424 100.826L258.41 117.84C258.048 118.201 257.588 118.447 257.087 118.547C256.586 118.647 256.067 118.596 255.595 118.4C255.123 118.205 254.719 117.874 254.436 117.449C254.152 117.024 254 116.525 254 116.014V106.75H246.25V91.2501H254Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M230.75 91.25V106.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M238.5 91.25V106.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <filter id="filter0_d_749_50" x="309" y="20" width="176" height="160" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_749_50" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_749_50" result="shape" />
                </filter>
            </defs>
        </svg>

    );
}

export default AssetDocumentToPusproject;