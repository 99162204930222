import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserRequest } from "../../../request";
import { getLocalToken, getLocalUser, setLocalUser } from "../../../utils";

const SettingProfileContext = createContext();

export const SettingProfileContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const user = getLocalUser();
    const token = getLocalToken();
    const [controller, setController] = useState({
        name: user.authFromServer.data.user.name ? user.authFromServer.data.user.name : 'Anonim',
        gender: user.authFromServer.data.user.gender,
        date_of_birth: user.authFromServer.data.user.date_of_birth,
    });

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSave = async () => {
        await updateUserRequest({ token: token.token, body: controller }).then((res) => {
            let authServer = { ...user.authFromServer };
            authServer.data.user = res.data.user
            setLocalUser({ ...user, authFromServer: authServer });
        });
    }

    return (
        <SettingProfileContext.Provider value={{ navigation, user, controller, onSetController, onSave }}>
            {children}
        </SettingProfileContext.Provider>
    );
}

export const UseSettingProfileContext = () => {
    return useContext(SettingProfileContext);
}