import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalUser } from "../../utils";

const ProfileContext = createContext();

export const ProfileContextProvider = ({ children }) => {
    const user = getLocalUser();
    const navigation = useNavigate();

    return (
        <ProfileContext.Provider value={{ user, navigation }}>
            {children}
        </ProfileContext.Provider>
    );
}

export const UseProfileContext = () => {
    return useContext(ProfileContext);
}