import { Content, SettingAside } from "../../components";

const SettingPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <SettingAside />
                <div className="grow px-3">
                    <h2 className="font-bold text-xl">Pengaturan</h2>
                </div>
            </div>
        </Content>
    );
}

export default SettingPage;