import { createContext, useContext, useState } from "react";
import { updateUserRequest } from "../../../request";
import { getLocalToken, getLocalUser, setLocalUser } from "../../../utils";

const SettingAccountContext = createContext();

export const SettingAccountContextProvider = ({ children }) => {
    const user = getLocalUser();
    const token = getLocalToken();
    const [controller, setController] = useState({
        email: user.authFromServer.data.user.email,
        phone_number: user.authFromServer.data.user.phone_number,
    });

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSave = async () => {
        await updateUserRequest({ token: token.token, body: controller }).then((res) => {
            let authServer = { ...user.authFromServer };
            authServer.data.user = res.data.user
            setLocalUser({ ...user, authFromServer: authServer });
        });
    }

    return (
        <SettingAccountContext.Provider value={{ user, controller, onSetController, onSave }}>
            {children}
        </SettingAccountContext.Provider>
    );
}

export const UseSettingAccountContext = () => {
    return useContext(SettingAccountContext);
}