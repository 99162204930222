import { AvAvatar1 } from "../../assets";
import { Button, Card, Content, ProfileAside } from "../../components";
import { UseProfileContext } from "../../contexts/profile";

const ProfilePage = () => {
    const { user, navigation } = UseProfileContext();

    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <ProfileAside />
                <Card className="grow flex gap-5">
                    <div>
                        <Card className="p-3 shadow-md w-[18rem]">
                            {user.authFromServer.data.user.photo ? (<img src={user.authFromServer.data.user.photo} alt="FotoProfile" className="w-full rounded-lg" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-full rounded-lg" />)}
                            <div className="mt-3">
                                <Button className="border py-[0.5rem]">Ubah Foto</Button>
                            </div>
                            <div className="mt-2 leading-3">
                                <span className="text-xs">Ukuran file maksimal 5.000.000 bytes (5Mb) dengan jenis file (PNG, JPG, JPEG)</span>
                            </div>
                        </Card>
                    </div>
                    <div className="grow">
                        <h2 className="font-bold text-xl">Biodata</h2>
                        <div className="mt-2 grid grid-cols-6">
                            <div><span className="font-semibold">Nama</span></div>
                            <div className="col-span-5">{user.authFromServer.data.user.name ? user.authFromServer.data.user.name : 'Anonim'}</div>
                        </div>
                        <div className="mt-2 grid grid-cols-6">
                            <div><span className="font-semibold">Jenis Kelamin</span></div>
                            {user.authFromServer.data.user.gender ? (<div className="col-span-5">{user.authFromServer.data.user.gender}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/profile')}>Tambah Jenis Kelamin</div>)}
                        </div>
                        <div className="mt-2 grid grid-cols-6">
                            <div><span className="font-semibold">Tanggal Lahir</span></div>
                            {user.authFromServer.data.user.date_of_birth ? (<div className="col-span-5">{user.authFromServer.data.user.date_of_birth}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/profile')}>Tambah Tanggal Lahir</div>)}
                        </div>
                        {/*  */}
                        <h2 className="font-bold text-xl mt-5">kontak</h2>
                        <div className="mt-2 grid grid-cols-6">
                            <div><span className="font-semibold">Email</span></div>
                            <div className="col-span-5">{user.authFromServer.data.user.email}</div>
                        </div>
                        <div className="mt-2 grid grid-cols-6">
                            <div><span className="font-semibold">Nomor Hp</span></div>
                            {user.authFromServer.data.user.phone_number ? (<div className="col-span-5">{user.authFromServer.data.user.phone_number}</div>) : (<div className="col-span-5 text-violet-600 font-medium cursor-pointer" onClick={() => navigation('/setting/account')}>Tambah Nomor Hp</div>)}
                        </div>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default ProfilePage;