import { Button, Content, InputDate, InputRadio, InputText, SettingAside } from "../../../components";
import { UseSettingProfileContext } from "../../../contexts/setting/profile";


const SettingProfilePage = () => {
    const { controller, onSetController, onSave } = UseSettingProfileContext();

    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <SettingAside />
                <div className="grow px-3">
                    <h2 className="font-bold text-xl">Biodata</h2>
                    <div className="mt-2 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Nama</h5>
                            <small>Silakan isi nama anda yang sesuai</small>
                        </div>
                        <InputText value={controller.name} onChange={(value) => onSetController('name', value)} />
                    </div>
                    <div className="mt-3 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Jenis Kelamin</h5>
                            <small>Pilih salah satu</small>
                        </div>
                        <div className="mt-2">
                            <InputRadio value={controller.gender} data={['Laki-Laki', 'Perempuan']} flex={true} onChange={(value) => onSetController('gender', value.value)} />
                        </div>
                    </div>
                    <div className="mt-3 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Tanggal Lahir</h5>
                            <small>Masukan tanggal lahir yang sesuai</small>
                        </div>
                        <InputDate value={controller.date_of_birth} onChange={(value) => onSetController('date_of_birth', value)} />
                    </div>
                    <div className="mt-5 flex">
                        <Button className="border bg-violet-500 text-white" onClick={() => onSave()}>Simpan</Button>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default SettingProfilePage;