import { Button, Content, SettingAside, InputNumber } from "../../../components";
import { InputEmail } from "../../../components/atoms";
import { UseSettingAccountContext } from "../../../contexts/setting/account";

const SettingAccountPage = () => {

    const { controller, onSetController, onSave } = UseSettingAccountContext();

    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <SettingAside />
                <div className="grow px-3">
                    <h2 className="font-bold text-xl">Akun</h2>
                    <div className="mt-2 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Email</h5>
                            <small>Silakan isi nama anda yang sesuai</small>
                        </div>
                        <InputEmail value={controller.email} onChange={(value) => onSetController('email', value)} />
                    </div>
                    <div className="mt-3 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Nomor Hp</h5>
                            <small>Masukan tanggal lahir yang sesuai</small>
                        </div>
                        <InputNumber value={controller.phone_number} onChange={(value) => onSetController('phone_number', value)} />
                    </div>
                    <div className="mt-3 flex">
                        <Button className="border bg-violet-500 text-white" onClick={() => onSave()}>Simpan</Button>
                    </div>
                    <div className="mt-4 max-w-[30rem]">
                        <div className="leading-3 mb-1">
                            <h5 className="font-bold">Ganti Password</h5>
                            <small>Masukan tanggal lahir yang sesuai</small>
                        </div>
                        <div className="flex">
                            <Button className="text-violet-500 border border-violet-500">Ganti Password</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default SettingAccountPage;