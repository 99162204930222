import { AssetDocumentToPusproject, AssetLogoGoogle, AssetShapesBg } from "../../assets";
import { Button, Card, Content, InputPassword } from "../../components";
import { InputEmail } from "../../components/atoms";
import { UseRegisterContext } from "../../contexts/auth/RegisterContext";

const RegisterPage = () => {
    const { navigation, loader, handleGoogleSignIn, onSetController, onSaved } = UseRegisterContext();

    return (
        <Content navbar={false} footer={false}>
            <div className="flex flex-wrap-reverse justify-evenly my-[6rem]">
                <div className="md:flex justify-center relative pt-5 hidden">
                    <div className="absolute top-16">
                        <AssetShapesBg width="349" height="319" />
                    </div>
                    <div className="z-10 text-center">
                        <h1 className="text-violet-500 font-bold text-5xl cursor-pointer" onClick={() => navigation('/')}>Pusproject</h1>
                        <div className="mt-20 mb-12">
                            <AssetDocumentToPusproject width="485" height="204" />
                        </div>
                        <h3 className="font-bold text-xl">Raih Banyak Keuntungan Dan Rasakan</h3>
                        <h4 className="font-medium">Permudah akses layanan dengan bergabung di pusproject</h4>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Card className="md:shadow-md border text-center py-6 px-12">
                        <h1 className="font-bold text-xl">Daftar Sekarang</h1>
                        <div className="flex gap-2 justify-center">
                            <span className="font-medium">Sudah punya akun ?</span>
                            <span className="font-semibold text-violet-500 cursor-pointer" onClick={() => navigation('/login')}>Masuk</span>
                        </div>
                        <div className="mt-5">
                            <Button className="border text-gray-600 py-[0.6rem] px-12 md:px-14 justify-center" onClick={() => handleGoogleSignIn()}>
                                <AssetLogoGoogle width="18" height="18" /> Daftar dengan Google
                            </Button>
                        </div>
                        <div className="my-5">
                            <span className="text-gray-400 font-medium">Atau daftar dengan</span>
                        </div>
                        <div className="text-start">
                            <h6 className="font-semibold">Nomor Telepon atau Email</h6>
                            <InputEmail placeholder="Contoh: contoh@pusproject.com" onChange={(value) => onSetController('email', value)} />
                        </div>
                        <div className="text-start mt-3">
                            <h6 className="font-semibold">Masukan Kata Sandi</h6>
                            <InputPassword placeholder="Password" onChange={(value) => onSetController('password', value)} />
                        </div>
                        <div className="mt-5">
                            <Button className="border bg-violet-500 text-white py-[0.6rem] px-12 justify-center" loader={loader.register} onClick={() => onSaved()}>
                                Daftar
                            </Button>
                        </div>
                        <div className="mt-5 leading-4">
                            <small>Dengan mendaftar, saya menyetujui</small><br />
                            <small><span className="font-semibold text-violet-500 cursor-pointer">Syarat dan Ketentuan</span> Serta <span className="font-semibold text-violet-500 cursor-pointer">Kebijakan Privasi</span></small>
                        </div>
                    </Card>
                </div>
            </div>
        </Content>
    );
}

export default RegisterPage;